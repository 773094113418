import { useApolloClient } from '@apollo/client';
import { Refresh, RestartAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Typography,
} from '@mui/material';
import { pink, red } from '@mui/material/colors';
import { BrandKey } from '@prismamedia/one-brandkey';
import {
  PeriodSelect,
  SearchParams,
  pluralize,
} from '@prismamedia/one-components';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  ArticleFormat,
  ArticleStatus,
} from '../../../__generated__/queries-web';
import {
  useRecipeCount,
  useRecipeCountGetter,
} from '../../../apollo/queries/recipes.recipe.graphql';
import {
  useSearchArticleCount,
  useSearchArticleCountGetter,
} from '../../../apollo/queries/searchArticles.web.graphql';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { statuses } from '../../../utils/statuses';
import { AuthorsSelect } from '../AuthorsSelect';
import { CategoriesSelect } from '../CategoriesSelect';
import { QualifiersSelect } from '../QualifiersSelect';
import { SourcesSelect } from '../SourcesSelect';
import { StatusesSelect } from '../StatusSelect';
import { TagsSelect } from '../TagsSelect';
import { getWhere, ignoreArticleQuery } from '../utils';
import { useStyles } from './styles';
import { Filters, Format, filtersAreDefault, getDefaultFilters } from './utils';

const REFETCH_INTERVAL = 60000; // 1min

interface FiltersDrawerProps {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
  searchParams: SearchParams;
}

export const FiltersDrawer: FC<FiltersDrawerProps> = ({
  filters,
  setFilters,
  searchParams,
}) => {
  const classes = useStyles();
  const [brandKey] = useGlobalBrandKey();
  const [newArticles, setNewArticles] = useState(false);
  const getArticleCount = useSearchArticleCountGetter();
  const getRecipeCount = useRecipeCountGetter();
  const client = useApolloClient();
  const skipArticle = ignoreArticleQuery(filters);
  const skipRecipe =
    !!filters.sourceIds?.length || !!filters.qualifierIds?.length;

  const {
    data: articleData,
    loading: articleLoading,
    error: articleError,
  } = useSearchArticleCount(
    {
      where: getWhere(filters, searchParams, brandKey).articleWhere,
    },
    skipArticle,
  );

  const { data: publishedData } = useSearchArticleCount(
    {
      where: getWhere(
        { ...filters, status: [ArticleStatus.Published] },
        searchParams,
        brandKey,
      ).articleWhere,
    },
    skipArticle,
  );

  const { data: scheduledData } = useSearchArticleCount(
    {
      where: getWhere(
        { ...filters, status: [ArticleStatus.Scheduled] },
        searchParams,
        brandKey,
      ).articleWhere,
    },
    skipArticle,
  );

  const { data: richData } = useSearchArticleCount({
    where: getWhere(
      { ...filters, format: [Format.Article] },
      searchParams,
      brandKey,
    ).articleWhere,
  });

  const { data: slideshowData } = useSearchArticleCount({
    where: getWhere(
      { ...filters, format: [Format.Slideshow] },
      searchParams,
      brandKey,
    ).articleWhere,
  });

  const {
    data: recipeData,
    loading: recipeLoading,
    error: recipeError,
  } = useRecipeCount(
    {
      where: getWhere(filters, searchParams, brandKey).recipeWhere,
    },
    skipRecipe,
  );

  const { data: publishedRecipeData } = useRecipeCount(
    {
      where: getWhere(
        { ...filters, status: [ArticleStatus.Published] },
        searchParams,
        brandKey,
      ).recipeWhere,
    },
    skipRecipe,
  );

  const { data: scheduledRecipeData } = useRecipeCount(
    {
      where: getWhere(
        { ...filters, status: [ArticleStatus.Scheduled] },
        searchParams,
        brandKey,
      ).recipeWhere,
    },
    skipRecipe,
  );

  const count =
    (articleData?.searchArticleCount || 0) + (recipeData?.recipeCount || 0);
  const publishedCount =
    (publishedData?.searchArticleCount || 0) +
    (publishedRecipeData?.recipeCount || 0);
  const scheduledCount =
    (scheduledData?.searchArticleCount || 0) +
    (scheduledRecipeData?.recipeCount || 0);

  const selectedStatus = (status: ArticleStatus) =>
    filters.status?.length === 1 && filters.status?.includes(status);

  const selectedFormat = (format: Format) =>
    filters.format?.length === 1 && filters.format?.includes(format);

  const quickFilters = [
    {
      id: 'statusPublished',
      label: pluralize(
        statuses(ArticleFormat.Rich)[ArticleStatus.Published].label,
        publishedCount,
      ),
      color: statuses(ArticleFormat.Rich)[ArticleStatus.Published].color,
      selected: selectedStatus(ArticleStatus.Published),
      count: publishedCount,
      onClick: () =>
        setFilters((prev) => ({
          ...prev,
          status: selectedStatus(ArticleStatus.Published)
            ? getDefaultFilters().status
            : [ArticleStatus.Published],
        })),
    },
    {
      id: 'statusScheduled',
      label: pluralize(
        statuses(ArticleFormat.Rich)[ArticleStatus.Scheduled].label,
        scheduledCount,
      ),
      color: statuses(ArticleFormat.Rich)[ArticleStatus.Scheduled].color,
      selected: selectedStatus(ArticleStatus.Scheduled),
      count: scheduledCount,
      onClick: () =>
        setFilters((prev) => ({
          ...prev,
          status: selectedStatus(ArticleStatus.Scheduled)
            ? getDefaultFilters().status
            : [ArticleStatus.Scheduled],
        })),
    },
    {
      id: 'formatRich',
      label: pluralize('Article', richData?.searchArticleCount),
      color: red[500],
      selected: selectedFormat(Format.Article),
      count: richData?.searchArticleCount,
      onClick: () =>
        setFilters((prev) => ({
          ...prev,
          format: selectedFormat(Format.Article)
            ? getDefaultFilters().format
            : [Format.Article],
        })),
    },
    {
      id: 'formatSlideshow',
      label: pluralize('Diapo', slideshowData?.searchArticleCount),
      color: '#3f51b5',
      selected: selectedFormat(Format.Slideshow),
      count: slideshowData?.searchArticleCount,
      onClick: () =>
        setFilters((prev) => ({
          ...prev,
          format: selectedFormat(Format.Slideshow)
            ? getDefaultFilters().format
            : [Format.Slideshow],
        })),
    },
    {
      id: 'formatRecipe',
      label: pluralize('Recette', recipeData?.recipeCount),
      color: pink[200],
      selected: selectedFormat(Format.Recipe),
      count: recipeData?.recipeCount,
      onClick: () =>
        setFilters((prev) => ({
          ...prev,
          format: selectedFormat(Format.Recipe)
            ? getDefaultFilters().format
            : [Format.Recipe],
        })),
      hide: brandKey && ![BrandKey.FAC, BrandKey.CAC].includes(brandKey),
    },
  ];

  useEffect(() => {
    setNewArticles(false);

    const interval = setInterval(async () => {
      const newArticleCount = await getArticleCount(
        {
          where: getWhere(filters, searchParams, brandKey).articleWhere,
        },
        false,
        'no-cache',
      );

      const newRecipeCount = await getRecipeCount(
        {
          where: getWhere(filters, searchParams, brandKey).recipeWhere,
        },
        false,
        'no-cache',
      );

      const newCount =
        (newArticleCount.data?.searchArticleCount || 0) +
        (newRecipeCount.data?.recipeCount || 0);

      if (newCount && count) {
        setNewArticles(newCount !== count);
      }
    }, REFETCH_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [brandKey, count, filters, getArticleCount, getRecipeCount, searchParams]);

  return (
    <>
      {newArticles && (
        <Button
          className={classes.refetchButton}
          startIcon={<Refresh />}
          variant="contained"
          onClick={() =>
            client.refetchQueries({
              updateCache: (cache) =>
                cache.modify({
                  fields: {
                    searchArticles: (prev, { DELETE }) => DELETE,
                    searchArticleCount: (prev, { DELETE }) => DELETE,
                    recipes: (prev, { DELETE }) => DELETE,
                    recipeCount: (prev, { DELETE }) => DELETE,
                  },
                }),
            })
          }
        >
          Nouveaux articles
        </Button>
      )}

      <div className={classes.countWrapper}>
        {articleLoading || recipeLoading ? (
          <CircularProgress size={32} />
        ) : articleError || recipeError ? (
          <Typography color="error">
            {articleError?.message || recipeError?.message}
          </Typography>
        ) : (
          <Typography variant="h6">
            {count} {pluralize('article', count)}
          </Typography>
        )}
      </div>

      <Button
        startIcon={<RestartAlt />}
        variant="outlined"
        disabled={filtersAreDefault(filters)}
        onClick={() => setFilters(getDefaultFilters())}
      >
        Réinitialiser
      </Button>

      <div className={classes.quickFilters}>
        {quickFilters
          .filter(({ hide }) => !hide)
          .map((quickFilter) => (
            <Card
              key={quickFilter.id}
              className={classes.quickFilterCard}
              sx={
                quickFilter.selected
                  ? {
                      outline: `2px solid ${quickFilter.color}`,
                    }
                  : undefined
              }
            >
              <CardActionArea
                className={classes.quickFilterCardContent}
                onClick={quickFilter.onClick}
              >
                <Box
                  className={classes.quickFilterColor}
                  sx={{ bgcolor: quickFilter.color }}
                ></Box>
                <div>
                  <Typography variant="caption" color="GrayText">
                    {quickFilter.label}
                  </Typography>
                  <Box
                    sx={{ height: 30, display: 'flex', alignItems: 'center' }}
                  >
                    {articleLoading ? (
                      <CircularProgress size={14} />
                    ) : (
                      <Typography variant="h6">{quickFilter.count}</Typography>
                    )}
                  </Box>
                </div>
              </CardActionArea>
            </Card>
          ))}
      </div>

      <PeriodSelect
        sx={{ mt: 2 }}
        value={{ startDate: filters.startDate, endDate: filters.endDate }}
        onChange={(value) => setFilters((prev) => ({ ...prev, ...value }))}
      />

      <StatusesSelect
        label="Statuts"
        className={classes.field}
        value={filters.status}
        onChange={(status) => setFilters((prev) => ({ ...prev, status }))}
      />

      <CategoriesSelect
        label="Catégories"
        className={classes.field}
        value={filters.categoryIds}
        onChange={(categoryIds) =>
          setFilters((prev) => ({ ...prev, categoryIds }))
        }
      />

      <TagsSelect
        label="Tags"
        className={classes.field}
        value={filters.tagIds}
        onChange={(tagIds) => setFilters((prev) => ({ ...prev, tagIds }))}
      />

      <QualifiersSelect
        label="Qualifiers"
        className={classes.field}
        value={filters.qualifierIds}
        onChange={(qualifierIds) =>
          setFilters((prev) => ({ ...prev, qualifierIds }))
        }
      />

      <SourcesSelect
        label="Sources"
        className={classes.field}
        value={filters.sourceIds}
        onChange={(sourceIds) => setFilters((prev) => ({ ...prev, sourceIds }))}
      />

      <AuthorsSelect
        label="Auteurs"
        brandKey={brandKey}
        value={filters.authorIds}
        onChange={(authorIds) => setFilters((prev) => ({ ...prev, authorIds }))}
        className={classes.field}
      />
    </>
  );
};
