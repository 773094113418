import { FC, ReactNode } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';

interface DialogStepperProps {
  onClose: DialogProps['onClose'];
  open: boolean;
  steps: string[];
  currentStep: number;
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
}

export const DialogStepper: FC<DialogStepperProps> = ({
  open,
  onClose,
  steps,
  currentStep,
  children,
  header,
  footer,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiDialog-paper': {
            width: '100%',
            maxWidth: '800px',
            height: '100%',
            padding: '40px 32px 16px 32px',
          },
        },
      }}
    >
      <IconButton
        color="inherit"
        onClick={(e) => onClose && onClose(e, 'escapeKeyDown')}
        aria-label="Close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stepper activeStep={currentStep}>
        {steps.map((stepName, i) => (
          <Step key={`affiliz-dialog-step-${i}`}>
            <StepLabel>{stepName}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {header && <Box>{header}</Box>}
      <DialogContent sx={{ display: 'flex', p: 0, overflow: 'hidden' }}>
        {children}
      </DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  );
};
