import React, { FC, MouseEvent } from 'react';
import { ProductInfos } from './types';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { Check as CheckIcon, Search as SearchIcon } from '@mui/icons-material';
import { priceRanges } from './config';
import { grey } from '@mui/material/colors';
import { ReactComponent as LogoPrismaSmall } from '../../../../assets/svg/logo-prisma-small.svg';
import { AffilizzProductType } from '../../../../__generated__/queries-web';

interface AffilizzSearchPropsHeader {
  keyword: string;
  priceRange: string;
  sortType: string;
  total: number;
  onSort: (sortType: string) => void;
  onProductSearch: (searchQuery: string) => void;
  onPriceChange: (min: number, max: number | null, value: string) => void;
}

export const AffilizzSearchHeader: FC<AffilizzSearchPropsHeader> = ({
  keyword,
  total,
  priceRange,
  sortType,
  onSort,
  onPriceChange,
  onProductSearch,
}) => {
  const handlePriceChange = (
    event: MouseEvent<HTMLElement>,
    newPriceRange: string,
  ) => {
    if (newPriceRange === null) {
      onPriceChange(0, null, '');
      return;
    }

    const result = priceRanges.find((r) => r.value === newPriceRange);

    if (result) {
      onPriceChange(result.min, result.max, result.value);
    }
  };

  return (
    <FormControl sx={{ width: '100%', mt: 4 }} variant="outlined" size="small">
      <InputLabel htmlFor="outlined-adornment-search">
        Rechercher un produit
      </InputLabel>
      <OutlinedInput
        autoFocus
        value={keyword}
        onChange={(e) => onProductSearch(e.target.value)}
        id="outlined-adornment-search"
        type="text"
        endAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        label="Rechercher un produit"
      />
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ mb: 1 }} variant="body2">
          Filtrer par prix
        </Typography>
        <>
          <ToggleButtonGroup
            fullWidth
            size="small"
            color="primary"
            value={priceRange}
            exclusive
            onChange={handlePriceChange}
            aria-label="text alignment"
          >
            {priceRanges.map((pR) => (
              <ToggleButton
                size="small"
                key={pR.value}
                sx={{ flexGrow: 1, p: 0.5 }}
                value={pR.value}
              >
                {pR.value}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </>
      </Box>
      {!!keyword && (
        <Stack
          sx={{ my: 1 }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          {!!total && (
            <Typography variant="body2">
              {total} résultat{total > 1 ? 's' : ''}
            </Typography>
          )}
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ ml: 'auto' }}
          >
            <Typography variant="body2">Trier par : </Typography>
            <FormControl
              size="small"
              variant="standard"
              sx={{ m: 2, minWidth: 120, ml: 'auto' }}
            >
              <Select
                sx={{ fontSize: 14 }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={sortType || 'NB_OFFERS'}
                onChange={(e) => onSort(e.target.value)}
                label="Trier par"
              >
                <MenuItem value="NB_OFFERS">Nombres d’offres</MenuItem>
                <MenuItem value="RELEVANCE">Pertinence</MenuItem>
                <MenuItem value="PRICE_ASC">Prix croissant</MenuItem>
                <MenuItem value="PRICE_DESC">Prix décroissant</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      )}
    </FormControl>
  );
};

interface AffilizProductCardProps {
  product: ProductInfos;
  onSelect: (p: ProductInfos) => void;
  isSelected: boolean;
  sx?: SxProps;
}

export const AffilizProductCard: FC<AffilizProductCardProps> = ({
  product: p,
  onSelect,
  isSelected,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  const lineClamp: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  };

  return (
    <Card
      onClick={() => onSelect(p)}
      variant="outlined"
      component="article"
      sx={{
        position: 'relative',
        p: 1,
        mt: 1,
        cursor: 'pointer',
        borderColor: isSelected ? theme.palette.primary.main : grey[300],
        ...sx,
      }}
      {...rest}
    >
      {p.meta.type === AffilizzProductType.CatalogProduct && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 8,
            right: 16,
            width: 24,
            height: 'auto',
            '& svg': { width: '100%' },
          }}
        >
          <LogoPrismaSmall />
        </Box>
      )}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          height: '100%',
          alignItems: 'center',
        }}
      >
        {isSelected && (
          <CheckIcon
            color="primary"
            sx={{
              position: 'absolute',
              right: 4,
              top: 4,
            }}
          />
        )}
        <CardMedia
          key={`media-${p.meta.id}`}
          component="img"
          src={p.defaultImage}
          sx={{
            width: '72px',
            height: '72px',
            maxHeight: '72px',
            objectFit: 'contain',
            flexShrink: 0,
          }}
        />
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            flexGrow: 0,
            p: 0,
            height: '100%',
            ml: 2,
          }}
        >
          <Typography
            component="h3"
            variant="body2"
            sx={{ mb: 1, fontWeight: 500, ...lineClamp }}
          >
            {p.name}
          </Typography>
          <Box
            component="p"
            sx={{ display: 'flex', alignItems: 'center', p: 0, m: 0 }}
          >
            <Chip
              color="primary"
              size="small"
              sx={{ borderRadius: 1, mr: 1 }}
              label={`${p.nbOffers} offre${p.nbOffers > 1 ? 's' : ''}`}
            />
            <Typography variant="body2" component="span">
              | à partir de{' '}
              <Typography
                variant="body2"
                sx={{ color: theme.palette.success.light }}
                component="span"
              >
                {p.minPrice}
                {p.currencyCode?.toUpperCase() === 'EUR' ? '€' : p.currencyCode}
              </Typography>
            </Typography>
          </Box>
        </CardContent>
        <span></span>
      </Box>
    </Card>
  );
};
