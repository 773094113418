import { PluginButtonProps } from '@prismamedia/one-components';
import { FC } from 'react';
import { ArticleFormat, BrandKey } from '../../../../__generated__/queries-web';
import { brandsOptions } from '../../../../utils/brands';
import { ArticleEditAction } from '../../reducer';
import { AffilizMediaButton } from './AffilizzMediaButton';
import { DumbBlock, DumbBlockProps } from './DumbBlock';

export interface AffilizPluginProps {
  brandKey: BrandKey;
  dispach: React.Dispatch<ArticleEditAction>;
  format: ArticleFormat;
}

export const AffilizzMediaPlugin = (
  brandKey: BrandKey,
  articleId: string,
  format: ArticleFormat,
  dispach: React.Dispatch<ArticleEditAction>,
): {
  type: string;
  buttonComponent: FC<PluginButtonProps>;
  blockComponent: FC<DumbBlockProps>;
} | null => {
  if (!brandsOptions[brandKey].affiliate || !articleId) {
    return null;
  }

  return {
    type: 'affilizz',
    buttonComponent: AffilizMediaButton({
      brandKey,
      dispach,
      format,
    }),
    blockComponent: DumbBlock,
  };
};
