import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { cloneDeep, get, isUndefined, set, uniqWith } from 'lodash';
import type { FC } from 'react';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import type {
  CreatePageCategory,
  UpdatePageCategory,
} from '../../__generated__/queries-web';
import type { PickOne } from '../../types/generic';
import { auth } from '../../utils/auth';
import { useSavePageCategory } from './apollo/mutations/category.web.graphql';
import { CategoriesContext } from './categories.context';
import {
  DEFAULT_CATEGORY_ERRORS,
  DEFAULT_CATEGORY_FORM,
  SAVE_CATEGORY_ERROR_MESSAGE,
  SAVE_CATEGORY_SUCCESS_MESSAGE,
} from './constants';
import { getCategory } from './models/category.model';
import type { Category, CategoryErrors, CategoryForm } from './types';
import { getCategoryFormErrors, getIsFormValid } from './utils';
import { SectionForm } from './views/Edit/components/Section/types';

const PRISTINE_CATEFORY_FORM = DEFAULT_CATEGORY_FORM;

interface HandleErrorsChangeParams {
  fieldName: keyof CategoryForm;
  value?: Partial<any>;
}
interface HandleFormChangeParams<T = CategoryErrors> {
  errors?: T;
  value?: PickOne<CategoryForm, keyof CategoryForm>;
  opts?: { setFormPristine: boolean };
  path: string[];
}

interface handleFormValidation extends Pick<HandleFormChangeParams, 'errors'> {
  newForm: CategoryForm;
  path?: string[];
}

interface Handlers {
  handleErrorsChange: (params: HandleErrorsChangeParams) => void;
  handleFormChange: <T = CategoryErrors>(
    params: HandleFormChangeParams<T>,
  ) => void;
  handleFormSubmit: (
    form: CategoryForm,
    cbFn?: (newCategory?: Category) => void,
    opts?: { skipNotification: boolean },
  ) => void;
  handleFormValidation: (params: handleFormValidation) => void;
  resetForm: () => void;
  setForm: (form: CategoryForm, initialization?: boolean) => void;
}

// CONTEXT
interface FormContextProps {
  errors: CategoryErrors;
  form: CategoryForm;
  formPristine: CategoryForm;
  handlers: Handlers;
  isFormDirty: boolean;
  isFormDisabled: boolean;
  isFormLoading: boolean;
  isFormInvalid: boolean;
  isFormPristine: boolean;
  isFormTouched: boolean;
  isFormValid: boolean;
}
const FormContext = createContext<FormContextProps>({
  errors: DEFAULT_CATEGORY_ERRORS,
  form: DEFAULT_CATEGORY_FORM,
  formPristine: PRISTINE_CATEFORY_FORM,
  handlers: {
    handleErrorsChange: () => {},
    handleFormChange: () => {},
    handleFormSubmit: () => {},
    handleFormValidation: () => {},
    resetForm: () => {},
    setForm: () => {},
  },
  isFormDirty: false,
  isFormDisabled: false,
  isFormLoading: false,
  isFormInvalid: true,
  isFormPristine: true,
  isFormTouched: false,
  isFormValid: false,
});

// PROVIDER
interface FormProviderProps {
  children: ReactNode;
}
const FormProvider: FC<FormProviderProps> = ({ children }) => {
  const { activeCategory } = useContext(CategoriesContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errors, setErrors] = useState<CategoryErrors>(DEFAULT_CATEGORY_ERRORS);
  const [form, setForm] = useState<CategoryForm>(DEFAULT_CATEGORY_FORM);

  const [isFormTouched, setFormTouched] = useState<boolean>(false);
  const [formPristine, setFormPristine] = useState<CategoryForm>(
    DEFAULT_CATEGORY_FORM,
  );

  const savePageCategory = useSavePageCategory();
  const { pushNotification } = useNotification();

  const handlers: Handlers = useMemo(
    () => ({
      handleErrorsChange: ({ fieldName, value }) => {
        setErrors((currentErrors: CategoryErrors) => {
          const newErrors = { ...currentErrors, [fieldName]: value };
          return newErrors;
        });
      },
      handleFormChange: ({ opts, path, value, ...rest }) => {
        setForm((currentForm) => {
          let currentValue = value;

          if (
            Array.isArray(currentValue) &&
            currentValue.length &&
            path.length &&
            path[0] === 'sections'
          ) {
            currentValue = uniqWith(
              currentValue as SectionForm[],
              (arrVal, othVal) => !!arrVal.id && arrVal.id === othVal.id,
            );
          }

          const newValue = !isUndefined(currentValue)
            ? currentValue
            : get(currentForm, path);
          const newForm = cloneDeep(currentForm);

          if (!isUndefined(value)) {
            set(newForm, path, newValue);
            setFormTouched(
              (currentFormTouched) =>
                currentFormTouched || !opts?.setFormPristine,
            );

            // We programmatically reseting the form
            if (opts?.setFormPristine) {
              setFormPristine(newForm);
            }

            handlers.handleFormValidation({ newForm, path, ...rest } as any);
          }

          return newForm;
        });
      },
      handleFormSubmit: async (newForm, cb, opts) => {
        setIsLoading(true);

        try {
          const { id, ...restForm } = newForm;
          const variables = {
            data: getCategory(restForm),
            where: { id },
          };

          const categorySavedResult = await savePageCategory(variables);

          const category =
            (categorySavedResult.data as UpdatePageCategory)?.updateCategory ||
            (categorySavedResult.data as CreatePageCategory)?.createCategory;

          setIsLoading(false);

          if (!opts?.skipNotification) {
            if (!category) {
              pushNotification({
                message: `
                  [${SAVE_CATEGORY_ERROR_MESSAGE}]
                `,
                type: NotificationTypeEnum.error,
              });

              return;
            }

            pushNotification({
              message: SAVE_CATEGORY_SUCCESS_MESSAGE,
              type: NotificationTypeEnum.success,
            });
          }

          cb?.((category as unknown) as Category);
        } catch (error) {
          setIsLoading(false);

          if (!opts?.skipNotification) {
            pushNotification({
              message: `
                [${SAVE_CATEGORY_ERROR_MESSAGE}]
                ${error}
              `,
              type: NotificationTypeEnum.error,
            });
          }

          cb?.();
        }
      },
      handleFormValidation: (params) => {
        // By default we using provided partial errors otherwise we computing new one
        setErrors((currentErrors) => {
          const newErrors =
            params.path && params.errors
              ? set(cloneDeep(currentErrors), params.path, params.errors)
              : getCategoryFormErrors({
                  ...params,
                  errors: currentErrors,
                  form: params.newForm,
                });

          return newErrors;
        });
      },
      resetForm: () => {
        setErrors(DEFAULT_CATEGORY_ERRORS);
        setFormTouched(false);

        handlers.setForm(DEFAULT_CATEGORY_FORM);
      },
      setForm: (newForm, initialization) => {
        setFormPristine(newForm);
        setForm(newForm);

        if (!initialization) {
          handlers.handleFormValidation({ newForm });
        }
      },
    }),
    [pushNotification, savePageCategory],
  );

  const isDirty = useMemo(
    () => JSON.stringify(formPristine) !== JSON.stringify(form),
    [form, formPristine],
  );

  const isPristine = !isDirty;

  const isDisabled = useMemo(
    () =>
      !!(
        activeCategory?.lockedBy?.id &&
        activeCategory.lockedBy.id !== auth?.user?.id
      ) || isLoading,
    [activeCategory, isLoading],
  );

  const isValid = useMemo(() => getIsFormValid(errors), [errors]);

  const isInvalid = !isValid;

  return (
    <FormContext.Provider
      value={{
        errors,
        form,
        formPristine,
        handlers,
        isFormLoading: isLoading,
        isFormDirty: isDirty,
        isFormDisabled: isDisabled,
        isFormInvalid: isInvalid,
        isFormPristine: isPristine,
        isFormTouched,
        isFormValid: isValid,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
