import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import { InfiniteList } from '@prismamedia/one-components';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { IndexRange } from 'react-virtualized';
import {
  ArticleFormat,
  SearchArticles_searchArticles,
} from '../../__generated__/queries-web';
import { useSearchArticles } from '../../apollo/queries/searchArticles.web.graphql';
import { paths } from '../../routing/Router/paths';
import { useStyles } from './styles';

interface ArticlesRelatedMediaDialogProps {
  title: string | null;
  idMedia?: string;
  articleFormat?: ArticleFormat;
  handleClose: () => void;
  handleDelete?: () => void;
}

export const ArticlesRelatedMediaDialog: FC<ArticlesRelatedMediaDialogProps> = ({
  title,
  idMedia,
  articleFormat,
  handleClose,
  handleDelete,
}) => {
  const { data, fetchMore, loading, error } = useSearchArticles(
    {
      first: 20,
      skip: 0,
      where: {
        medias_contains: idMedia,
        format: ArticleFormat.Rich,
      },
    },
    false,
  );

  const classes = useStyles();

  const CardItem = ({
    id,
    title: titleArticle,
    editedAt,
    brandKey,
  }: SearchArticles_searchArticles) => {
    const path = generatePath(paths.ARTICLE_EDIT, {
      brandKey,
      articleType: 'article',
      id,
    });

    const date = editedAt
      ? format(new Date(editedAt), 'dd/MM/yyyy à HH:mm', {
          locale: fr,
        })
      : undefined;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Box className={classes.boxCard}>
            <Typography className={classes.linkBoxCard}>
              <Link target="_blank" href={`#${path}`} color="inherit">
                {titleArticle}
              </Link>
            </Typography>
            {date && (
              <Typography
                className={classes.dateBoxCard}
                color="primary"
                component="span"
              >
                {date}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };

  const handleFetchMore = ({ startIndex }: IndexRange) =>
    fetchMore({
      variables: {
        skip: startIndex,
      },
    });

  return (
    <Dialog onClose={handleClose} open={true} maxWidth="md" fullWidth={true}>
      <AppBar className={classes.wrapperTitle} color="inherit">
        <Toolbar>
          <Typography variant="h6">
            {handleDelete
              ? `Mettre en corbeille ${
                  articleFormat === 'Video' ? 'la vidéo' : 'le diaporama'
                }`
              : `Les articles reliés à ${
                  articleFormat === 'Video' ? 'cette vidéo' : 'ce diaporama'
                }`}
          </Typography>
        </Toolbar>
      </AppBar>
      {handleDelete && !!data?.articles.length && (
        <>
          <Box className={classes.titleDialogBox}>
            <Typography component="span">
              Êtes - vous sûr de vouloir mettre en corbeille
              {articleFormat === 'Video' ? 'la vidéo' : 'le diaporama'}:
            </Typography>
            {title} ?
          </Box>
          <Box className={classes.contentDialogBox}>
            <Typography component="span">
              Attention
              {articleFormat === 'Video' ? 'la vidéo' : 'le diaporama'} est dans
              les articles suivants:
            </Typography>
          </Box>
        </>
      )}
      {!!data?.articles.length && (
        <Box className={classes.listWrapper}>
          <InfiniteList
            fetchMore={handleFetchMore}
            error={error}
            list={data?.articles}
            loading={loading}
            rowHeight={70}
            rowRenderer={(article: SearchArticles_searchArticles) => (
              <CardItem {...article} />
            )}
          />
        </Box>
      )}
      <DialogActions className={classes.dialogActions}>
        {handleDelete && (
          <>
            <Button onClick={handleClose} variant="outlined">
              Annuler
            </Button>
            <Button onClick={handleDelete} color="primary" variant="contained">
              Supprimer
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
