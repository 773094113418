import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  list: {
    height: `calc(80vh - 50px)`,
    position: 'relative',
    listStyle: 'none',
  },
}));
