export const steps = ['Rechercher un produit', 'Enrichir la carte', 'Aperçu'];

interface PriceRange {
  min: number;
  max: number | null;
  value: string;
}

export const priceRanges: PriceRange[] = [
  { min: 0, max: 50, value: '0-50' },
  { min: 51, max: 100, value: '51-100' },
  { min: 101, max: 250, value: '101-250' },
  { min: 251, max: 500, value: '251-500' },
  { min: 501, max: 1000, value: '501-1000' },
  { min: 1001, max: 2000, value: '1k-2k' },
  { min: 2001, max: null, value: '2k+' },
];

export const errorMessage = {
  ERROR_CREATE_PREVIEW:
    'Une erreur est survenue lors de la création de la carte affilizz.',
  ERROR_DISPLAY_PREVIEW:
    "Une erreur est survenue lors de l'affichage de la carte affilizz.",
  ERROR_ADD_CATALOG_PRODUCT:
    "Une erreur est survenue lors de l'ajout du produit au catalogue.",
  ERROR_LOAD_SCRIPT:
    'Une erreur est survenue lors du chargement du script affilizz.',
};

export const stepText = {
  details: {
    ADD_TO_CATALOG_TEXT: 'Ajouter au catalogue',
  },
};
