import {
  Add,
  FolderOutlined,
  ModeEditOutlineOutlined,
} from '@mui/icons-material';

export const MAX_CATEGORY_DEPTH_LEVEL = 5;

export enum CategoryCardActionTypes {
  ARCHIVE = 'ARCHIVE',
  CREATE_CHILD = 'CREATE_CHILD',
  MODIFY = 'MODIFY',
  OPEN = 'OPEN',
  PREVIEW = 'PREVIEW',
}

export const MENU_ITEMS = [
  {
    disabled: false,
    icon: <FolderOutlined />,
    id: CategoryCardActionTypes.OPEN,
    label: 'Ouvrir',
  },
  {
    disabled: false,
    icon: <Add />,
    id: CategoryCardActionTypes.CREATE_CHILD,
    label: 'Créer une sous-page',
  },
  {
    disabled: false,
    icon: <ModeEditOutlineOutlined />,
    id: CategoryCardActionTypes.MODIFY,
    label: 'Modifier',
  },
];
