import { gql, useQuery } from '@apollo/client';
import {
  GetAffilizzDesigns,
  GetSearchAffiliationProduct,
  GetSearchAffiliationProductVariables,
} from '../../__generated__/queries-web';

const GET_AFFILIZ_PRODUCTS_QUERY = gql`
  query GetSearchAffiliationProduct(
    $keyword: String!
    $minPrice: Float
    $maxPrice: Float
    $sort: AffilizzProductSearchSor
    $page: Int
    $size: Int
  ) {
    searchAffiliationProduct(
      keyword: $keyword
      minPrice: $minPrice
      maxPrice: $maxPrice
      sort: $sort
      page: $page
      size: $size
    ) {
      totalElements
      totalPages
      pageable {
        pageNumber
      }
      content {
        type
        id
        productLocalizedId
        currencyCode
        defaultImage
        minPrice
        maxPrice
        nbOffers
        name
        upsides
        downsides
      }
    }
  }
`;

export const useGetAffilizzProducts = (
  variables: GetSearchAffiliationProductVariables,
  skip?: boolean,
) =>
  useQuery<GetSearchAffiliationProduct, GetSearchAffiliationProductVariables>(
    GET_AFFILIZ_PRODUCTS_QUERY,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      skip,
      variables,
    },
  );

const GET_AFFILIZ_DESIGNS_QUERY = gql`
  query GetAffilizzDesigns {
    searchAffilliationDesign {
      id
      name
    }
  }
`;

export const useGetAffilizzDesigns = (skip?: boolean) =>
  useQuery<GetAffilizzDesigns, any>(GET_AFFILIZ_DESIGNS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip,
  });
