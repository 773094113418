import * as R from 'ramda';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  GetArticle_article,
  GetArticle_article_articleAuthors,
  GetArticle_article_articleCategories,
  GetArticle_article_articleChannels,
  GetArticle_article_articleEvents,
  GetArticle_article_articleGuides,
  GetArticle_article_articleMachineTags_machineTag,
  GetArticle_article_articleQualifiers,
  GetArticle_article_articleTags,
  UpsertArticleVariables,
} from '../../../__generated__/queries-web';
import { ArticleState } from '../reducer';

interface BasicArticleInfo {
  title: string;
  secondaryTitle: string | null;
  partnerTitle: string | null;
  medias: string | null;
  lead: string | null;
  body: string | null;
  publishedAt: string | null;
  brandKey: BrandKey;
  editedAt: string;
  slug: string | null;
  format: ArticleFormat;
  status: ArticleStatus;
}
interface queryType {
  create?: unknown[];
  delete?: unknown[];
}

const parseBasicInfo = (obj: BasicArticleInfo) =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === 'string' && !value ? null : value,
    ]),
  ) as BasicArticleInfo;

const getBasicArticleInfo = (
  article: ArticleState,
  edited?: boolean,
): BasicArticleInfo =>
  parseBasicInfo({
    title: article.title,
    secondaryTitle: article.secondaryTitle,
    partnerTitle: article.partnerTitle,
    medias: article.medias?.length ? JSON.stringify(article.medias) : null,
    lead: article.lead ? JSON.stringify(article.lead) : null,
    body: article.body ? JSON.stringify(article.body) : null,
    publishedAt: article.publishedAt
      ? new Date(article.publishedAt).toISOString()
      : null,
    brandKey: article.brandKey,
    editedAt: edited ? article.editedAt! : new Date().toISOString(),
    slug: article.slug,
    format: article.format,
    status: article.status,
  });

export const getCreateForResource = <R extends { [key in string]: any }>(
  resources: R[],
  resourceSubKey: keyof R,
): any => {
  return resources.map((resource: R, i: number) => ({
    order: i + 1,
    [resourceSubKey]: {
      connect: { id: resource[resourceSubKey].id },
    },
  }));
};

const getCreateMachineTags = (
  machineTag?: GetArticle_article_articleMachineTags_machineTag,
) => {
  if (machineTag) {
    const { id, tag } = machineTag;
    return [
      {
        order: 0,
        machineTag: {
          upsert: {
            where: { id },
            update: {},
            create: {
              id,
              tag,
            },
          },
        },
      },
    ];
  }
};

export const mapDeleteRelationship = R.map(R.pick(['order']));

const purgeByPath = (path: string, obj: UpsertArticleVariables) => {
  const properties = [
    'articleAuthors',
    'articleCategories',
    'articleQualifiers',
    'articleTags',
    'articleChannels',
    'articleEvents',
    'articleGuides',
    'articleMachineTags',
    'articleHistories',
  ];

  let result = { ...obj };
  for (const property of properties) {
    if (
      !R.pathSatisfies(
        (query: queryType) =>
          Boolean(query?.create?.length) || Boolean(query?.delete?.length),
        [path, property],
        result,
      )
    ) {
      result = R.dissocPath([path, property], result);
    }
  }
  return result;
};

const purgeResult = (obj: UpsertArticleVariables) => {
  let result = { ...obj };
  result = purgeByPath('create', result);
  result = purgeByPath('update', result);
  return result;
};

export const articleMutationVariables = (
  article: ArticleState,
  prevArticle: GetArticle_article | null,
  edited?: boolean,
): UpsertArticleVariables => {
  const createAuthors = getCreateForResource<GetArticle_article_articleAuthors>(
    article.articleAuthors,
    'author',
  );
  const createCategories = getCreateForResource<
    GetArticle_article_articleCategories
  >(article.articleCategories, 'category');
  const createQualifiers = getCreateForResource<
    GetArticle_article_articleQualifiers
  >(article.articleQualifiers, 'qualifier');
  const createTags = getCreateForResource<GetArticle_article_articleTags>(
    article.articleTags,
    'tag',
  );
  const createChannels = getCreateForResource<
    GetArticle_article_articleChannels
  >(article.articleChannels, 'category');
  const createEvents = getCreateForResource<GetArticle_article_articleEvents>(
    article.articleEvents,
    'category',
  );
  const createGuides = getCreateForResource<GetArticle_article_articleGuides>(
    article.articleGuides,
    'category',
  );

  const createMachineTags = getCreateMachineTags(
    article?.articleMachineTags?.[0]?.machineTag,
  );

  const result = purgeResult({
    create: {
      id: article.id,
      ...getBasicArticleInfo(article),
      articleAuthors: {
        create: createAuthors,
      },
      articleCategories: {
        create: createCategories,
      },
      articleQualifiers: {
        create: createQualifiers,
      },
      articleTags: {
        create: createTags,
      },
      articleChannels: {
        create: createChannels,
      },
      articleEvents: {
        create: createEvents,
      },
      articleGuides: {
        create: createGuides,
      },
      articleMachineTags: {
        create: createMachineTags,
      },
      source: article.source ? { connect: { id: article.source.id } } : null,
    },
    ...(prevArticle
      ? {
          update: {
            ...getBasicArticleInfo(article, edited),
            articleAuthors: {
              create: createAuthors,
              delete: mapDeleteRelationship(prevArticle.articleAuthors),
            },
            articleCategories: {
              create: createCategories,
              delete: mapDeleteRelationship(prevArticle.articleCategories),
            },
            articleQualifiers: {
              create: createQualifiers,
              delete: mapDeleteRelationship(prevArticle.articleQualifiers),
            },
            articleTags: {
              create: createTags,
              delete: mapDeleteRelationship(prevArticle.articleTags),
            },
            articleChannels: {
              create: createChannels,
              delete: mapDeleteRelationship(prevArticle.articleChannels),
            },
            articleEvents: {
              create: createEvents,
              delete: mapDeleteRelationship(prevArticle.articleEvents),
            },
            articleGuides: {
              create: createGuides,
              delete: mapDeleteRelationship(prevArticle.articleGuides),
            },
            source: {
              ...(article.source ? { connect: { id: article.source.id } } : {}),
              ...(!article.source && prevArticle.source
                ? { disconnect: true }
                : {}),
            },
          },
        }
      : { update: {} }),
    where: {
      id: article.id,
    },
  });
  return result;
};
