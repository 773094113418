import type { EditableFieldsErrors } from '@prismamedia/one-components';
import { OneMedia } from '@prismamedia/one-components';
import { useMemo } from 'react';
import type { CategoryFormSectionUrlContent } from '../types';
import { useEditSection } from '../useEditSection';
import type { UrlProps } from './';

interface Handlers {
  handleContentUrlChange: (value: string) => void;
  handleContentMediaChange: (
    value: (OneMedia & { errors?: EditableFieldsErrors })[],
  ) => void;
}

interface UseEditUrlProps {
  content: CategoryFormSectionUrlContent;
  errors: any;
  handlers: Handlers;
}

export const useEditUrl = (sectionProps: UrlProps): UseEditUrlProps => {
  const { errors, handlers: sectionHandlers } = useEditSection(sectionProps.id);

  const sectionContent = useMemo(() => sectionProps.content, [
    sectionProps.content,
  ]);

  const sectionErrors = useMemo(() => errors?.content, [errors?.content])!;

  const handlers = useMemo(
    (): Handlers => ({
      handleContentUrlChange: (newValue) => {
        sectionHandlers.handleSectionChange({
          fieldName: 'content',
          value: newValue,
          path: ['0'],
        });
      },
      handleContentMediaChange: (updatedMedias) => {
        const [newMedias] = updatedMedias.map(
          ({ errors: mediaErrors, ...newMedia }) => newMedia,
        );
        const [newErrors] = updatedMedias.map(
          ({ errors: mediaErrors }) => mediaErrors,
        );

        const newContent = newMedias
          ? [sectionContent.at(0), newMedias]
          : [sectionContent.at(0)];

        const newSectionErrors = {
          ...errors,
          content: newErrors
            ? [sectionErrors?.at(0), newErrors]
            : [sectionErrors?.at(0)],
        };

        sectionHandlers.handleSectionChange({
          fieldName: 'content',
          value: newContent,
          errors: newSectionErrors as any,
        });
      },
    }),
    [errors, sectionContent, sectionErrors, sectionHandlers],
  );

  return { content: sectionContent, errors: sectionErrors, handlers };
};
