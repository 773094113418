import React, { FC, memo, useEffect } from 'react';
import { PluginBlockProps } from '@prismamedia/one-components';
import { Fab, Paper, Tooltip } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { loadScript } from './utils';

export type DumbBlockProps<T = unknown> = PluginBlockProps<
  T & { html: string; [x: string]: any },
  any
>;

export const DumbBlock: FC<DumbBlockProps> = memo((props) => {
  const { container, data, blockProps } = props;

  useEffect(() => {
    loadScript(config.AFFILIZZ_SCRIPT_URL, null, null);
  }, []);

  const onDelete = () => {
    container.remove();
    blockProps.setReadOnly(false);
  };

  return (
    <Paper
      sx={{
        maxWidth: '800px',
        position: 'relative',
        '&:hover > button': { opacity: 1 },
      }}
    >
      {onDelete && (
        <Tooltip title="Supprimer">
          <Fab
            size="small"
            color="primary"
            sx={{
              position: 'absolute',
              zIndex: 1,
              right: 8,
              top: 8,
              opacity: 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
            onClick={onDelete}
          >
            <DeleteIcon />
          </Fab>
        </Tooltip>
      )}
      {/* eslint-disable */}
      <div dangerouslySetInnerHTML={{ __html: data.html }} />
    </Paper>
  );
});
