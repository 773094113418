import { gql, useMutation } from '@apollo/client';
import {
  CreateAffiliationCard,
  CreateAffiliationCardVariables,
  CreateAffiliationCatalogProduct,
  CreateAffiliationCatalogProductVariables,
} from '../../__generated__/queries-web';

const CREATE_AFFILIZ_CATALOG_PRODUCT = gql`
  mutation CreateAffiliationCatalogProduct(
    $localizedProductId: String!
    $name: String!
  ) {
    createAffiliationCatalogProduct(
      localizedProductId: $localizedProductId
      name: $name
    ) {
      productCatalogLocalizedId
    }
  }
`;

const CREATE_AFFILIZ_PRODUCT_CARD = gql`
  mutation CreateAffiliationCard(
    $localizedProductId: String!
    $title: String!
    $tag: String
    $description: String
    $positivePoints: [String]
    $negativePoints: [String]
  ) {
    createAffiliationCard(
      localizedProductId: $localizedProductId
      title: $title
      tag: $tag
      description: $description
      positivePoints: $positivePoints
      negativePoints: $negativePoints
    ) {
      id
      name
      contents {
        id
      }
    }
  }
`;

export const useCreateAffiliationCard = () => {
  return useMutation<CreateAffiliationCard, CreateAffiliationCardVariables>(
    CREATE_AFFILIZ_PRODUCT_CARD,
  );
};

export const useCreateAffiliationCatalogProduct = () => {
  return useMutation<
    CreateAffiliationCatalogProduct,
    CreateAffiliationCatalogProductVariables
  >(CREATE_AFFILIZ_CATALOG_PRODUCT);
};
