import { SxProps, TextFieldProps } from '@mui/material';
import {
  AutocompleteItem,
  AutocompleteSelect,
} from '@prismamedia/one-components';
import { FC, useCallback } from 'react';
import { searchImageSourceCategories } from '../../../../apollo/sharedQueries/getSourcesCategories.web.graphql';
import { getCreditInList } from './utils';

interface ImageSourceSelectProps {
  label?: string;
  value?: AutocompleteItem;
  onChange?: (value?: AutocompleteItem) => void;
  className?: string;
  TextFieldProps?: TextFieldProps;
  sx?: SxProps;
  valueToSelectFrom?: string;
}

export const ImageSourceSelect: FC<ImageSourceSelectProps> = ({
  value,
  onChange,
  className,
  label = "Source de l'image",
  TextFieldProps: textFieldProps,
  sx,
  valueToSelectFrom,
}) => {
  const fetchList = useCallback(
    (search: string) =>
      searchImageSourceCategories(search).then((sources) =>
        sources?.reduce(
          (prev, { id, title, imageSources }) => [
            ...prev,
            { id, label: title, disabled: true },
            ...imageSources.map((child) => ({
              id: child.id,
              label: child.title,
              level: 1,
            })),
          ],
          [] as AutocompleteItem[],
        ),
      ),
    [],
  );

  // Select source based on valueToSelectFrom
  if (!value && valueToSelectFrom) {
    fetchList('')
      .then((imageSourceCategories) => {
        const sourceFound = getCreditInList(
          imageSourceCategories,
          valueToSelectFrom,
        );
        if (sourceFound) {
          onChange?.(sourceFound);
        }
      })
      .catch(() => {});
  }

  return (
    <AutocompleteSelect
      sx={sx}
      label={label}
      value={value}
      className={className}
      fetchList={fetchList}
      onChange={onChange}
      TextFieldProps={textFieldProps}
    />
  );
};
